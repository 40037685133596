import { apiSlice } from "../../api/apiSlice";
import { UserResponse, UserPostResponse, User } from "../../models/user";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<UserResponse, { page?: number, limit?: number }>({
            query: ({ page, limit }) => `/users?page=${page}&limit=${limit}`,
            transformResponse: (response: unknown) => {
                const data = (response as { data: UserResponse }).data;
                return data;
            },
        }),
        postUser: builder.mutation<UserPostResponse, Partial<User>>({
            query: (user) => ({
                url: '/users',
                method: 'POST',
                body: user,
            }),
        }),
        updateUser: builder.mutation<UserPostResponse, { id: string; user: Partial<User> }>({
            query: ({ id, user }) => ({
                url: `/users/${id}`,
                method: 'PATCH',
                body: user,
            }),
        }),
    }),
});

export const { useGetUsersQuery, useUpdateUserMutation, usePostUserMutation } = userApiSlice;
