import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { IAddress } from "../../types/Address";

export interface IAddressSelectionProps {
  label: string;
  addresses: IAddress[];
  initialValue: number;
  onChange?: (addressId: number) => void;
}

export const AddressSelection: React.FC<IAddressSelectionProps> = ({
  label,
  addresses,
  initialValue,
  onChange,
}: IAddressSelectionProps) => {

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      {addresses.length > 0 && (
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={initialValue}
          onChange={(e) => {
            const id = e.target.value as number;
            if (onChange) onChange(id);
          }}
          label="Age"
        >
          {addresses.map((address) => {
            return (
              <MenuItem key={address.id} value={address.id}>
                {address.name}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
};
