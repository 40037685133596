import React, { useState, MouseEvent } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { NavMenuItem } from "../types/NavMenuItem";

export interface ISelectionMenuProps {
  title: string;
  items?: NavMenuItem[];
  onSelectItem: (item: any) => void;
  onMainMenuClick?: () => void;
}

export const NavMenuList = ({
  title,
  items,
  onSelectItem,
  onMainMenuClick,
}: ISelectionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    // If there there are sub menu items, show the menu
    // Otherwise, handle main menu click
    if (items && items.length > 0) {
      setAnchorEl(event.currentTarget);
    } else if (onMainMenuClick) {
      onMainMenuClick();
    }
  };
  const handleClose = (item: any) => {
    setAnchorEl(null);
    onSelectItem(item);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "white" }}
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Show items */}
        {items &&
          items.length > 0 &&
          items.map((item, index) => {
            return (
              <MenuItem key={index} onClick={() => handleClose(item)}>
                {item.title}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};
