import { createApi, fetchBaseQuery, BaseQueryFn } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api-ltidev.sansook.net/api",
    prepareHeaders: (headers, { getState }) => {
      // const token = getState().token;
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwOTdkMzIzLTQ4MjAtNDdjMS1hMzg0LTU1NmEwNzU2MmEwNyIsIm90cCI6ZmFsc2UsImlhdCI6MTY4NzY2NzAyNywiZXhwIjoxNjg3NzUzNDI3fQ.o02YTIm_DR6DVF5H4WDkiNfbakNLKsXSxd4twstfZ6o"
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});
