export const nameValidation = (name?: string) => {
  if (!name) {
    return "Name is required";
  }
};

export const phoneValidation = (phone?: string) => {
  if (!phone) {
    return "Phone number is required";
  }
  if (isNaN(Number(phone))) {
    return "Phone number must be number";
  }
  // if (phone?.length !== 8) {
  //   return "Phone number must be 8 digits";
  // }
};

export const agentCodeValidation = (agentCode?: string) => {
  if (!agentCode) {
    return "Agent code is required";
  }
};
