import React from "react";
import { Navbar } from "../../common/Navbar";
import { Dashboard } from "./Dashboard";

export const HomePage: React.FC = () => {
  return (
    <div>
      <Navbar />
      <Dashboard />
    </div>
  );
};
