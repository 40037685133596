import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { loginByUsernamPassword } from "../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("vaxeng@gmail.com");
  const [password, setPassword] = useState("123");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const navigation = useNavigate();
  const signIn = useSignIn();

  const handleClose = () => {
    setError(null);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Clear error message
      setError(null);
      // Perform login logic here
      console.log(`Email: ${email}, Password: ${password}`);
      setIsLoading(true);
      /**
       * Call login service
       */
      const response = await loginByUsernamPassword(email, password);
      // Login success
      console.log(response);
      setIsLoading(false);
      signIn({
        token: response.data.token,
        tokenType: "Bearer",
        authState: response.data,
        expiresIn: 3600,
      });
      // Go to home page
      navigation("/");
    } catch (error) {
      setIsLoading(false);
      setError("Login failed");
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        Loading...
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      {/* Show error message */}
      <Snackbar
        open={error !== undefined && error !== null}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Typography variant="h4" component="h2" gutterBottom>
        LTI
      </Typography>
      {/* Login form */}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username or Email"
          value={email}
          onChange={handleEmailChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={handlePasswordChange}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </form>
    </Box>
  );
};
