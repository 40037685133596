import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import AdbIcon from "@mui/icons-material/Adb";
import { styled, alpha } from "@mui/material/styles";
import { AccountCircle } from "@mui/icons-material";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { NavMenuList } from "./NavMenuList";
import { NavMenuItem } from "../types/NavMenuItem";

const navMenus = [
  {
    title: "Agents",
    link: "/agents",
    // items: [
    //   {
    //     title: "All agents",
    //     icon: "icon1",
    //     link: "/agents",
    //   },
    //   {
    //     title: "option2",
    //     icon: "icon2",
    //     link: "/option2",
    //   },
    // ] as NavMenuItem[],
  },
  {
    title: "Settings",
    items: [
      {
        title: "option2",
        icon: "icon2",
        link: "/option2",
      },
    ] as NavMenuItem[],
  },
];

export const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const singOut = useSignOut();
  const navigate = useNavigate();

  // const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleNavOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  const handleNavClose = () => {
    setAnchorElNav(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    singOut();
    navigate("/login");
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  return (
    <AppBar position="static">
      <Toolbar>
        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          LTI
        </Typography> */}
        <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
          {navMenus.map((menu, index) => {
            return (
              <NavMenuList
                key={index}
                title={menu.title}
                items={menu.items}
                onSelectItem={(item) => {
                  if (item && item.link) navigate(item.link);
                }}
                onMainMenuClick={() => {
                  // In case there is no sub menu, navigate to the main menu link
                  if (menu.link) navigate(menu.link);
                }}
              />
            );
          })}
        </Box>
        <Box mr={2}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            // onClick={handleMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          {/* <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogOut}>Test</MenuItem>
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
