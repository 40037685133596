import React, { useEffect } from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import "./HomePage.css";
import TaskIcon from "@mui/icons-material/Task";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useAuthUser } from "react-auth-kit";
import { Link } from "react-router-dom";

export const Dashboard: React.FC = () => {
  const menuItems = [
    { title: "Item 1", icon: "icon-1.png" },
    { title: "Item 2", icon: "icon-2.png" },
    { title: "Item 3", icon: "icon-3.png" },
    { title: "Item 4", icon: "icon-4.png" },
  ];
  const authState = useAuthUser();

  return (
    <div>
      {/* <Typography variant="h3" component="h1" gutterBottom>
        Welcome to LTI
      </Typography> */}
      <Grid container className="grid-menu-container grid-menu">
        {menuItems.map((item, index) => (
          <Grid item p={2} xs={6} sm={3} key={index}>
            <Link to="/tasks" className="menu-item-icon">
              <Card>
                <CardContent>
                  <SupportAgentIcon />
                  <Typography variant="h5" component="h2">
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
