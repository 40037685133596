import React, {
  useEffect,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Grid,
  Snackbar,
  Alert,
  RadioGroup,
  Radio,
  Stack,
  Typography,
  FormControl,
} from "@mui/material";
import { AddressSelection } from "./AddressSelection";
import { IAddress, IProvince } from "../../types/Address";
import { IAgent, MARKET_REGIONS } from "../../types/Agent";
import { getProvince } from "../../services/AddressService";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import {
  agentCodeValidation,
  nameValidation,
  phoneValidation,
} from "./AgentFormValidation";
import { createAgent } from "../../services/agent-service";
import { handleApiErrorResponse } from "../../utils/ApiUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export interface AddNewAgentDialogProps {
  open: boolean;
  /**
   * @param result 0: Cancel, 1: Success
   * @returns
   */
  onClose: (result: number) => void;
  // onSubmit: (agent: IAgent) => void;
}

export const AddNewAgentDialog: React.FC<AddNewAgentDialogProps> = ({
  open,
  onClose,
}: // onSubmit,
AddNewAgentDialogProps) => {
  const authHeader = useAuthHeader();
  const authUser = useAuthUser();
  const initialState: IAgent = {
    // Add creating user
    userId: authUser()?.id,
    firstname: "",
    lastname: "",
    gender: "f",
    dateOfBirth: "2000-01-01",
    phone: "",
    region: undefined,
    provinceId: undefined,
    districtId: undefined,
    village: "",
    firstCommission: undefined,
    nextCommission: undefined,
    paymentTypeId: null,
    bankName: null,
    bankAccount: null,
    remark: undefined,
  };
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<IAgent>(initialState);
  const [provinces, setProvinces] = React.useState<IProvince[]>([]);
  const [districts, setDistricts] = React.useState<IAddress[]>([]);
  const [selectedProvince, setSelectedProvince] = React.useState<IProvince>();
  const [selectedDistrict, setSelectedDistrict] = React.useState<IAddress>();
  // Errors
  const [phoneError, setPhoneError] = useState<string | undefined | null>();
  const [agentCodeError, setAgentCodeError] = useState<
    string | undefined | null
  >();
  const [nameError, setNameError] = useState<string | undefined | null>();
  const [apiError, setApiError] = useState<string | undefined | null>();

  // Get provinces data
  useEffect(() => {
    setLoading(true);
    getProvince(authHeader()).then((res) => {
      const provinces = res.data.rows;
      if (provinces && provinces.length > 0) {
        // Set all provinces data
        setProvinces(provinces);
        // Set selected province for initial select index 0
        const initialProvince = provinces[0];
        setSelectedProvince(initialProvince);
        formData.provinceId = initialProvince.id;
        // Set initial region
        formData.region = initialProvince.region;
        // Set districts data for initial select index 0
        if (initialProvince.districts && initialProvince.districts.length > 0) {
          setDistricts(initialProvince.districts);
          setSelectedDistrict(initialProvince.districts[0]);
        }
      }
      setLoading(false);
    });
  }, []);

  // On a input change we update the form state of that input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    // Validate form data
    const nameError = nameValidation(formData.firstname);
    const phoneError = phoneValidation(formData.phone);
    const agentCodeError = agentCodeValidation(formData.agentCode);
    // Set errors
    setNameError(nameError);
    setPhoneError(phoneError);
    setAgentCodeError(agentCodeError);
    /**
     *  If there is no error we submit the form data to API service
     */
    if (!nameError && !phoneError && !agentCodeError) {
      formData.provinceId = selectedProvince?.id;
      formData.districtId = selectedDistrict?.id;
      formData.region = selectedProvince?.region;
      // Set loading
      setLoading(true);
      createAgent(authHeader(), formData)
        .then((res) => {
          setLoading(false);
          onClose(1);
        })
        .catch((error) => {
          setLoading(false);
          const err = handleApiErrorResponse(error);
          setApiError(err);
        });
    }
  };

  const onCloseToast = () => {
    setApiError(undefined);
  };

  const Loading = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "400px",
          height: "400px",
        }}
      >
        <h4>Loading...</h4>
      </Box>
    );
  };

  return (
    <React.Fragment>
      {/* Error message */}
      <Snackbar
        open={apiError !== undefined && apiError !== null}
        autoHideDuration={3000}
        onClose={onCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={onCloseToast} severity="error" sx={{ width: "100%" }}>
          {apiError}
        </Alert>
      </Snackbar>
      {
        // If loading is true we show loading component
        loading ? (
          <Loading />
        ) : (
          <form>
            <Dialog open={open} onClose={onClose}>
              <DialogTitle>ເພີ່ມໃຫມ່</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Frist Name"
                      name="firstname"
                      value={formData?.firstname}
                      onChange={handleChange}
                      error={nameError ? true : false}
                      helperText={nameError}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Last Name"
                      name="lastname"
                      value={formData?.lastname}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Agent Code"
                      name="agentCode"
                      value={formData?.agentCode}
                      onChange={handleChange}
                      error={agentCodeError ? true : false}
                      helperText={agentCodeError}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Phone"
                      name="phone"
                      type="tel"
                      value={formData?.phone}
                      onChange={handleChange}
                      error={phoneError ? true : false}
                      helperText={phoneError}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Controlled picker"
                        value={dayjs(formData?.dateOfBirth)}
                        onChange={(newValue) => {
                          const dateStr = dayjs(newValue).format("YYYY-MM-DD");
                          console.log(dateStr);
                          setFormData((prev) => ({
                            ...prev,
                            dateOfBirth: dateStr,
                          }));
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <RadioGroup
                        row
                        defaultValue={formData?.gender}
                        name="gender"
                        value={formData?.gender}
                        onChange={handleChange}
                        sx={{ mx: 1 }}
                      >
                        <Stack direction="row" spacing={1}>
                          <Radio value="f" />
                          <Typography>ຜູ້ຫຍິງ</Typography>
                        </Stack>
                        <Stack direction="row">
                          <Radio value="m" />
                          <Typography>ຜູ້ຊາຍ</Typography>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {/* Select address */}
                  <Grid item sm={4}>
                    <AddressSelection
                      label="Select province"
                      addresses={provinces}
                      initialValue={selectedProvince ? selectedProvince.id : 0}
                      onChange={(provinceId) => {
                        // Change to new selected province
                        const newSelectedProvince = provinces.find(
                          (p) => p.id === provinceId
                        );
                        setSelectedProvince(newSelectedProvince);
                        // Also set region based on selected province
                        setFormData((prev) => ({
                          ...prev,
                          region: newSelectedProvince?.region ?? "",
                        }));
                        // Update districts data according to selected province, also need to
                        // reset selected district to the first district of the selected province
                        const newDistricts =
                          newSelectedProvince?.districts ?? [];
                        setDistricts(newDistricts);
                        const newSelectedDistrict = newDistricts[0];
                        setSelectedDistrict(newSelectedDistrict);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <AddressSelection
                      label="Select districts"
                      addresses={districts}
                      initialValue={selectedDistrict?.id ?? 0}
                      onChange={(districtId) => {
                        // Change selected district
                        const newSelectedDistrict = districts.find(
                          (d) => d.id === districtId
                        );
                        setSelectedDistrict(newSelectedDistrict);
                      }}
                    />
                  </Grid>
                  {
                    <Grid item sm={4}>
                      <TextField
                        label="Village"
                        name="village"
                        fullWidth
                        value={formData?.village}
                        onChange={handleChange}
                      />
                    </Grid>
                  }
                  <Grid item sm={4}>
                    <TextField
                      disabled
                      label="Region"
                      name="region"
                      fullWidth
                      value={
                        MARKET_REGIONS.find(
                          (r) => r.region === formData?.region
                        )?.name ?? "N/A"
                      }
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label="ສາຍການຕະຫລາດ"
                      placeholder="ລະຫັດ"
                      name="market"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onClose(0);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )
      }
    </React.Fragment>
  );
};
