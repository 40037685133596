import { apiSlice } from "../../api/apiSlice";
import { RolesResponse } from "../../models/roles";

export const rolesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query<RolesResponse, void>({
            query: () => `/roles?q=all`,
            transformResponse: (response: unknown) => {
                const data = (response as { data: RolesResponse }).data;
                return data;
            },
        }),
    }),
});

export const { useGetRolesQuery } = rolesApiSlice;
