import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../api/apiSlice'; // Import your apiSlice

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    // Add other reducers here if needed
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// Export the RootState type for type inference
export type RootState = ReturnType<typeof store.getState>;

// Export a typed version of the useDispatch hook
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export default store;
