import axios from "axios";
import { agentsPath } from "../constants/api-config";
import { PageQuery } from "../types/PageQuery";
import { IApiResponse } from "../types/ApiResponse";
import { IProvince } from "../types/Address";
import { be } from "date-fns/locale";
import { IAgent } from "../types/Agent";

export const getAllAgents = async (
  bearerHeader: string,
  pageQuery: PageQuery
): Promise<IApiResponse<IProvince[]>> => {
  const response = await axios.get(`${agentsPath}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: bearerHeader,
    },
    params: pageQuery,
  });
  return response.data;
};

export const createAgent = async (bearerHeader: string, data: IAgent) => {
  const response = await axios.post(`${agentsPath}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: bearerHeader,
    },
  });
  return response.data;
};
