export interface IAgent {
  id?: number | null;
  userId?: string | null;
  marketerId?: number | null;
  agentCode?: string;
  firstname?: string;
  lastname?: string;
  gender?: string;
  dateOfBirth?: string;
  phone?: string;
  region?: string;
  provinceId?: number;
  districtId?: number;
  village?: string;
  firstCommission?: string;
  nextCommission?: string;
  paymentTypeId?: null | number;
  bankName?: null | string;
  bankAccount?: null | string;
  remark?: string;
  isDelete?: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IMarketRegion {
  region: string;
  name: string;
}

export const MARKET_REGIONS: IMarketRegion[] = [
  { region: "north", name: "ພາກເຫນືອ" },
  { region: "center", name: "ພາກກາງ" },
  { region: "south", name: "ພາກໃຕ້" },
];
