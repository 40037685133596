import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    CircularProgress
} from '@mui/material';

import "./index.css"

import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { AddDialog } from './AddDialog';
import { useGetUsersQuery } from '../../features/users/userApi';
import { User } from '../../models/user';


export const UserAddPage: React.FC = () => {

    const { data: userList, isLoading: userLoading, isError: loadUserError, isSuccess: loadUserSuccess } = useGetUsersQuery({ page: 1, limit: 7 });

    const [showAddDialog, setShowAddDialog] = useState(false);

    const handleOpen = () => {
        setSelectedUser({
            email: '',
            password: '',
            phone: '',
            role: '',
            id: ''
        })
        setShowAddDialog(true);
    };

    const handleClose = () => {
        setShowAddDialog(false);
    };


    const [selectedUser, setSelectedUser] = useState<User>({
        email: '',
        password: '',
        phone: '',
        role: '',
        id: ''
    })



    const UserList = () => {
        return (
            <div>
                <table className="table table-striped shadow-sm">
                    <thead>
                        <tr>
                            <th className='bg-primary shadow-lg text-white'>ລຳດັບ</th>
                            <th className='bg-primary shadow-lg text-white'>ອີເມວ</th>
                            <th className='bg-primary text-white'>ເບີໂທລະສັບ</th>
                            <th className='bg-primary text-white'>ສະຖານະ</th>
                            <th className='bg-primary text-white'>ບົກບາດ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList?.rows.map((user, index) => (
                            <tr key={index}>
                                <td className='pt-3'>{index + 1}</td>
                                <td className='pt-3'>{user.email}</td>
                                <td className='pt-3'>{user.phone ? user.phone : '---------'}</td>
                                <td className='pt-3'>{user.isActive ? (<span>ກຳລັງໃຊ້ງານ</span>) : (<p>ປິດໃຊ້ງານ</p>)}</td>
                                <td className='pt-3'>{user.role}</td>
                                <td className='py-2'>
                                    <IconButton
                                        onClick={() => {
                                            setSelectedUser({
                                                email: user.email,
                                                password: user.password,
                                                phone: user.phone,
                                                role: user.role,
                                                id: user.id
                                            })
                                            setShowAddDialog(true)
                                        }}
                                        color="primary">
                                        <UpdateIcon />
                                    </IconButton>
                                    <IconButton style={{ color: '#e63946' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>

                <div>
                    <Dialog open={showAddDialog} onClose={handleClose}>
                        <DialogTitle>ເພີ່ມຜູ້ໃຊ້ໃຫມ່</DialogTitle>
                        <DialogContent>
                            <AddDialog userProp={selectedUser} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                ຍົກເລິກ
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }

    const SearchBar = () => {
        return (
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField sx={{
                        width: 500
                    }}
                        id="input-with-sx"
                        label="ຄົ້ນຫາຜູ້ໃຊ້"
                        variant="standard" />
                </Box>
            </Box>
        );
    }

    return (
        <div className='center'>
            {loadUserSuccess && (<>
                <div className='row'>
                    <div className='col-6'>
                        <SearchBar />
                    </div>
                    <div className='col-6 mt-3'>
                        <IconButton onClick={handleOpen} color="primary" sx={{ borderRadius: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
                            <AddIcon />
                            <Typography variant="body2">ເພີ່ມຜູ້ໃຊ້ໃຫມ່</Typography>
                        </IconButton>
                    </div>
                </div>
                <div className='row w-75 mt-3'>
                    <UserList />
                </div>
            </>
            )}
            <div>
                <Dialog open={userLoading}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

