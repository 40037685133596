import axios from "axios";
import { provincesPath } from "../constants/api-config";
import { IApiResponse } from "../types/ApiResponse";
import { IProvince } from "../types/Address";

export const getProvince = async (
  bearerHeader: string
): Promise<IApiResponse<IProvince[]>> => {
  const response = await axios.get(provincesPath, {
    headers: {
      "Content-Type": "application/json",
      Authorization: bearerHeader,
    },
  });
  return response.data;
};
