import { logInPath } from "../constants/api-config";
import axios from "axios";

export const loginByUsernamPassword = async (
  username: string,
  password: string
) => {
  const response = await axios.post(logInPath, {
    username,
    password,
  });
  return response.data;
};
