export const getPagination = () => {};

export const handleApiErrorResponse = (apiError: any) => {
  if (apiError.response) {
    // The request was made, but the server responded with a status code that falls out of the range of 2xx
    console.log("Status code:", apiError.response.status);
    console.log("Response data:", apiError.response.data);
    if (apiError.response.data.error.response.message instanceof Array) {
      const messages = apiError.response.data.error.response
        .message as string[];
      return messages.join(", ");
    }
    return "Something went wrong. Please try again later.";
  } else if (apiError.request) {
    // The request was made, but no response was received
    console.log("No response received:", apiError.request);
    return "Something went wrong. Please try again later.";
  } else {
    // Something happened in setting up the request that triggered an error
    console.log("Error:", apiError.message);
    return "Something went wrong. Please try again later.";
  }
};
