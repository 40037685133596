import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { LoginPage } from "./pages/auth";
import { HomePage } from "./pages/home";
import { RequireAuth } from "react-auth-kit";
import { AuthProvider } from "react-auth-kit";
import { AgentsPage } from "./pages/agents";
import { UserAddPage } from "./pages/users/userAdd";
import "./App.css"
import './font.css'; // Import the CSS file for custom fonts
import { createTheme, Typography, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store/store'; // Import your Redux store

function App() {

  const theme = createTheme({
    typography: { fontFamily: ["Noto Sans Lao", "cursive"].join(",") }
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Typography component="div">
          <div className="App">
            <AuthProvider
              authType={"cookie"}
              authName={"_auth"}
              cookieDomain={window.location.hostname}
              cookieSecure={false}
            >
              <Router>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RequireAuth loginPath="/login">
                        <HomePage />
                      </RequireAuth>
                    }
                  />
                  <Route path="/useradd" Component={UserAddPage} />
                  <Route path="/login" Component={LoginPage} />
                  <Route path="/agents" Component={AgentsPage} />
                  <Route path="*" element={<h1>Not Found</h1>} />
                </Routes>
              </Router>
            </AuthProvider>
          </div>

        </Typography>
      </ThemeProvider>
    </Provider>

  );
}

export default App;
