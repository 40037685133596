import {
    TextField,
    Button,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Alert,
    Snackbar,
    Dialog,
    DialogContent,
    CircularProgress,
    SelectChangeEvent
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { User } from "../../models/user"
import { useGetRolesQuery, } from '../../features/roles/rolesApi';
import { usePostUserMutation, useUpdateUserMutation } from '../../features/users/userApi';


interface Props {
    userProp: User
}

export const AddDialog: FC<Props> = ({ userProp }) => {




    const [errors, setErrors] = useState<Partial<User>>({});
    const [user, setUser] = useState<User>({ email: '', phone: '', password: '', role: '' });

    const { data: rolesList, isSuccess: loadRolesSuccess } = useGetRolesQuery();

    const [addUser, { isLoading: userIsAdding, isError: addUserError, isSuccess: addUserSuccess }] = usePostUserMutation();
    const [updateUser, { isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess }] = useUpdateUserMutation();


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setUser({ ...user, [event.target.name]: event.target.value });


    };





    useEffect(() => {
        if (!user.role && loadRolesSuccess) {
            setUser((prevUser) => ({
                ...prevUser,
                role: rolesList[0].id.toString(),
            }));
        }
    }, [user.role, loadRolesSuccess]);



    const handleRoleChange = (event: SelectChangeEvent<string | number>) => {
        const selectedRole = event.target.value as string;
        setUser((prevUser) => ({
            ...prevUser,
            role: selectedRole,
        }));
    };



    const [passworConfirm, setPasswordComfirm] = useState('')
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const validationErrors: Partial<User> = {};

        if (!user.email) {
            validationErrors.email = 'ກະລຸນາໃສ່ອິເມວ';
        }

        if (user.password !== passworConfirm) {
            validationErrors.password = 'ລະຫັດຜ່ານບໍ່ຕົງກັນ';
        }
        if (!user.phone) {
            validationErrors.phone = 'ກະລຸນາໃສ່ເບີໂທລະສັບ';
        }


        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {

            if (userProp.id) {
                updateUser({ id: userProp.id, user: user })
                if (updateSuccess) {
                    console.log("add user success")
                    setShowSuccess(true)
                }

                if (updateError) {
                    console.log("add user failed")
                    setShowFailed(true)
                }
            }
            else {
                addUser(user);
                if (addUserSuccess) {
                    console.log("add user success")
                    setShowSuccess(true)
                }

                if (addUserError) {
                    console.log("add user failed")
                    setShowFailed(true)
                }
            }



        }
    };




    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    }



    const handlePasswordConfirmChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target
        setPasswordComfirm(value)
    }







    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);


    useEffect(() => {
        if (userProp.id) {
            setUser({
                email: userProp.email,
                password: "",
                phone: userProp.phone,
                role: userProp.role
            })
        }
    }, [])

    useEffect(() => {
        if (showFailed) {
            // Set a timeout to hide the Snackbar after 3 seconds
            const timeoutId = setTimeout(() => {
                setShowFailed(false);
            }, 3000);

            // Clear the timeout when the component is unmounted or showFailed state changes
            return () => clearTimeout(timeoutId);
        }
        if (showSuccess) {
            // Set a timeout to hide the Snackbar after 3 seconds
            const timeoutId = setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
            // Clear the timeout when the component is unmounted or showFailed state changes
            return () => clearTimeout(timeoutId);
        }

    }, [showFailed, showSuccess]);




    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '300px' }}>
                <TextField
                    label="ອີເມວ"
                    name="email"
                    value={user.email}
                    size="small"
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{
                        width: 500,
                        maxWidth: '100%',
                    }}
                />

                <TextField
                    label="ເບີໂທລະສັບ"
                    name="phone"
                    value={user.phone}
                    onChange={handleInputChange}
                    size="small"
                    inputMode="tel"
                    error={!!errors.phone}
                    helperText={errors.phone}
                    sx={{
                        width: 500,
                        maxWidth: '100%',
                    }}
                />

                <TextField
                    label="ລະຫັດຜ່ານ"
                    name="password"
                    value={user.password}
                    onChange={handleInputChange}
                    size="small"
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password}
                    sx={{
                        width: 500,
                        maxWidth: '100%',
                    }}
                />

                <TextField
                    label="ຢືນຢັນລະຫັດຜ່ານ"
                    value={passworConfirm}
                    onChange={handlePasswordConfirmChange}
                    size="small"
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password}
                    sx={{
                        width: 500,
                        maxWidth: '100%',
                    }}
                />

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">ສະຖານະ</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Age"
                        name="role"
                        onChange={handleRoleChange}
                        value={user.role}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {rolesList?.map((role) => (
                            <MenuItem key={role.id} value={role.name}>
                                {role.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Snackbar
                    open={showFailed}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="error">ເພີ່ມບໍ່ສຳເລັດ!</Alert>
                </Snackbar>


                <Snackbar
                    open={showSuccess}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="success">ເພີ່ມສຳເລັດ!</Alert>
                </Snackbar>
                <Dialog open={userIsAdding || updateLoading}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
                <Button variant="contained" type="submit">
                    ເພີ່ມ
                </Button>
            </Box>
        </form>
    )
}