import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Box,
  Container,
  Grid,
  Snackbar,
  TableFooter,
  TablePagination,
  Typography,
  styled,
} from "@mui/material";
import { getAllAgents } from "../../services/agent-service";
import { IAgent } from "../../types/Agent";
import { useAuthHeader } from "react-auth-kit";
import { SearchBox } from "../../common/SearchBox";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { PageQuery } from "../../types/PageQuery";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { AddNewAgentDialog } from "./AddNewAgentDialog";

export interface PaginationProps {
  count: number;
  countPage: number;
  currentPage: number;
  nextPage: number;
  previousPage: number;
}

const rowsPerPage = [25, 50, 100, { label: "All", value: -1 }];

export const AgentsPage: React.FC = () => {
  const authHeader = useAuthHeader();
  const [searchParams, setSearchParams] = useSearchParams();
  const [agents, setAgents] = useState<IAgent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [selected, setSelected] = React.useState<Date>();
  const [page, setPage] = React.useState<PaginationProps | null>(null);
  const [pageQuery, setPageQuery] = React.useState<PageQuery>(() => {
    // Set initial page query from url
    const page = searchParams.get("page") || "1";
    const limit = searchParams.get("limit") || "25";
    return {
      page: parseInt(page),
      limit: parseInt(limit),
    };
  });
  const [openAddNewAgent, setOpenAddNewAgent] = useState<boolean>(false);
  const [addAgentResult, setAddAgentResult] = React.useState<number>(0);

  useEffect(() => {
    setLoading(true);
    getAllAgents(authHeader(), pageQuery)
      .then((res) => {
        setLoading(false);
        // Set page data
        setPage(res.data as PaginationProps);
        // Set agents data
        setAgents(res.data.rows);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [pageQuery]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    console.log("current page: ", page);
    setPageQuery(
      (prevState) =>
        ({
          ...prevState,
          page: page + 1,
        } as PageQuery)
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log("rows per page: ", event.target.value);

    setPageQuery(
      (prevState) =>
        ({
          ...prevState,
          limit: parseInt(event.target.value),
        } as PageQuery)
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  if (loading) {
    return (
      <Container>
        <Box mt={4} p={5}>
          <Typography variant="h5" gutterBottom>
            Loading...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      {/* Success message */}
      <Snackbar
        open={addAgentResult === 1}
        autoHideDuration={3000}
        onClose={() => setAddAgentResult(0)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAddAgentResult(0)}
          severity="success"
          sx={{ width: "100%" }}
        >
          "Agent created successfully!"
        </Alert>
      </Snackbar>
      {openAddNewAgent && (
        <AddNewAgentDialog
          open={openAddNewAgent}
          onClose={(result) => {
            setOpenAddNewAgent(false);
            setAddAgentResult(result);
          }}
        />
      )}
      <Box mt={4}>
        {/* Header menu */}
        <Box>
          <Typography variant="h5" gutterBottom>
            Agents
          </Typography>
          <Grid container>
            <Grid item xs={8}>
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <SearchBox onChange={(e) => {}} />
                <Button sx={{ p: "10px" }}>ຄົ້ນຫາ</Button>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={() => {
                  console.log("Add new agent");

                  setOpenAddNewAgent(true);
                }}
              >
                ເພີ່ມຕົວແທນ
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* Header menu */}
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="Agent table">
            <TableHead>
              <TableRow>
                <TableCell>ລຳດັບ</TableCell>
                <TableCell>ລະຫັດຕົວແທນ</TableCell>
                <TableCell align="right">ຊື່ຕົວແທນ</TableCell>
                <TableCell align="right">ເບີໂທ</TableCell>
                <TableCell align="right">ບ້ານ</TableCell>
                <TableCell align="right">ເມືອງ</TableCell>
                <TableCell align="right">ແຂວງ</TableCell>
                <TableCell align="right">ພາກ</TableCell>
                <TableCell align="right">ຄ່າຄອມທຳອິດ</TableCell>
                <TableCell align="right">ຄ່າຄອມຕໍ່ໄປ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agents.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.agentCode}
                  </TableCell>
                  <TableCell align="right">
                    {row.firstname} {row.lastname}
                  </TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                  <TableCell align="right">{row.village}</TableCell>
                  <TableCell align="right">{row.districtId}</TableCell>
                  <TableCell align="right">{row.provinceId}</TableCell>
                  <TableCell align="right">{row.region}</TableCell>
                  <TableCell align="right">{row.firstCommission}</TableCell>
                  <TableCell align="right">{row.nextCommission}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPage}
                  colSpan={3}
                  count={page?.count || 0}
                  rowsPerPage={pageQuery.limit}
                  page={page ? page.currentPage - 1 : 0}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};
